<template>
  <div class="w-auto">
    <button
      @click="$emit('click')"
      class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 flex items-center focus:outline-none"
    >
      <slot></slot><span class="text-botonT">{{ botonpText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "botonPrimario",
  props: ["botonpText"]
};
</script>
